import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap";
import PropertyBanner from "../../../images/propertydetails/propertybanner-mobile.jpg";
import propertybannerTable from "../../../images/propertydetails/propertybannerTable.jpg";
import propertybannerDesktop from "../../../images/propertydetails/propertybanner-desktop.jpg";

import PropertyVideo from "../../../images/propertydetails/vidoePoster.jpg";
import PlayVideo from "../../Play/PlayVideo";
import "./PropertyBenner.scss"
import { ImageModule } from "../../../modules/Image_Module";
import { youtube_parser } from "../../common/utils";
import FsLightbox from 'fslightbox-react';
import Slider from "react-slick"
import $ from "jquery"

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// styles

// markup

const PropertyBenner = ( props ) => {

  const [isPlay, setPlay] = useState(false);
  const [youtubeId, setYoutubeId] = useState('');

  const openVideo = (youtube_id) => {
    setPlay(true);
    setYoutubeId(youtube_id);
  }
  
console.log('console-log', props)
  var youtube_url = props.banner_data.Youtube_Video_URL && youtube_parser(props.banner_data.Youtube_Video_URL);

  const [toggler, setToggler] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLighboxMobile = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint:1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint:1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      
    ]
  };

  const [ indexed, setIndex ] = useState(1)
  const afterChangeHandler = (d) => {
    setIndex(d+1)
  }

  // useEffect(() => {
  //   $(".floorplanClick").click(function() {
  //     $('html, body').animate({
  //       scrollTop: $("#floorplans").offset().top - 130
  //     }, 'smooth');
  //   })
  // })

  const scrollToPos = (div_id) =>{
    $('html, body').animate({
      scrollTop: $("#"+div_id).offset().top - 120
    }, 'smooth');
  }

  // console.log("$$$$", props.banner_data);

  let processedImages = JSON.stringify({});
  if (props.banner_data && props.banner_data.imagetransforms && props.banner_data.imagetransforms.Upload_Images_Transforms) {
      processedImages = props.banner_data.imagetransforms.Upload_Images_Transforms;
  }

  var lightImages2 = props.banner_data.Upload_Images && props.banner_data.Upload_Images.map(img => img.url);

  //console.log('lightImages2', lightImages2, photoIndex);

  return (

    <React.Fragment>
      <div className="new-property-banner new_project">
        <div className="col-img">
          {
            props.banner_data &&
            <picture>
              <Slider {...settings}
                afterChange = {
                  v => afterChangeHandler(v)
                }
              >
                {
                  props.banner_data.Upload_Images.map(sliderimg => {
                    const slider_image_url = sliderimg
                    return <>
                      <ImageModule ImageSrc={slider_image_url} imagename={"new-project.Upload_Images.details"} article_id={props.banner_data.id} imagetransforms={props.imagetransforms} ggfxNew={true} />
                    </>
                  })
                }
                {/* <ImageModule ImageSrc={props.banner_data.Title_Image} /> */}
              </Slider>
            </picture>
          }

          {/* <FsLightbox
            toggler={ toggler }
            sources= {
              props.banner_data.Upload_Images.map(lightboximg => {
                const lightbox_image_url = lightboximg
                return <>
                  <ImageModule ImageSrc={lightbox_image_url} />
                </>
              })
            }
          /> */}

        {isOpen && (
          <Lightbox
            mainSrc={lightImages2[photoIndex]}
            nextSrc={lightImages2[(photoIndex + 1) % lightImages2.length]}
            prevSrc={lightImages2[(photoIndex + lightImages2.length - 1) % lightImages2.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + lightImages2.length - 1) % lightImages2.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % lightImages2.length)
            }
          />
        )}

          <div className="property-banner-content">
            <Container>
              <Row>
                <Col>
                  <div className="property-banner-tag">
                    {/* <span className="full-view" onClick={(e) => openLighboxMobile(e, 0)}>
                      <i className="icon-full-view"></i>
                    </span>
                    <span>
                      {indexed}/{props.banner_data.Upload_Images.length} Photos
                    </span> */}
                    <span className="images-count" onClick={(e) => openLighboxMobile(e, 0)}><i className="icon-full-view"></i> {indexed}/{props.banner_data.Upload_Images.length} Photos</span>

                    { youtube_url && 
                      <span className="video" onClick = { e => openVideo(youtube_url)}>Video</span>
                    }

                    {
                      props.banner_data && props.banner_data.Floorplans && props.banner_data.Floorplans.length > 0 && 
                      <span className="floorplanClick" onClick={() => scrollToPos('floorplans')}>Floorplan</span>
                    }
                    
                  </div>
                </Col>
              </Row>
            </Container>

          </div>
        </div>
        <div className="col-img d-none img-zoom">
          <img src={PropertyBanner} alt="" />
          <strong className="play-btn-icon" onClick={e => { setPlay(true) }}>
            <i className="icon-play-btn"></i>
          </strong>
        </div>

        <PlayVideo
          isOpen={isPlay}
          isCloseFunction={setPlay}
          videoId={youtubeId}
          isAutoPlay={1}
        />
      </div>
    </React.Fragment>
  )
}

export default PropertyBenner